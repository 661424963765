import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';
import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';

const Origin = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add meta tags to prevent indexing
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'noindex, nofollow';
    document.head.appendChild(metaRobots);
    
    // Fade-in animation
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    
    return () => {
      clearTimeout(timer);
      // Clean up meta tag when component unmounts
      document.head.removeChild(metaRobots);
    };
  }, []);

  return (
    <div className="min-h-screen bg-brand-navy text-brand-white">
      <Navbar />
      <div className="container mx-auto px-6 py-16 md:py-24">
        <div className="max-w-3xl mx-auto">
          {/* Back Button */}
          <Link to="/about" className="inline-flex items-center text-brand-slate hover:text-brand-white mb-8 group">
            <ArrowLeft className="mr-2 h-4 w-4 transition-transform group-hover:-translate-x-1" />
            Back to About
          </Link>

          <h1 className="text-4xl md:text-5xl font-bold mb-6">Our Origin Story</h1>
          
          {/* Optional Subtitle */}
          <p className="text-xl text-brand-slate mb-10">
            Born from ambition, forged in tragedy: The extraordinary story of RMBG
          </p>

          {/* Main Content Area */}
          <div className="mt-16 glass-card p-8 md:p-10 rounded-xl border border-brand-white/10 shadow-xl bg-brand-navy/40 backdrop-blur-lg space-y-8 text-lg leading-relaxed text-brand-white/90">
            <p>
              In the mid-90s, Aike Müller was a tech-savvy teenager living in Germany. He spent much of his free time exploring the rapidly evolving world of computers and the internet. Aike developed a strong passion for technology, and as he grew older, he realized the importance of blending technology with business. He decided to move to the Netherlands to pursue a degree in Economics and Information Management, followed by an additional degree in Cybersecurity.
            </p>
            <p>
              After completing his studies, Aike landed his first job at PwC, where he honed his skills as an information strategist and cybersecurity professional. In 2011, he attended an international IT conference in Amsterdam, where he met Frank Rothchild, Elizabeth Bloomfield, and Ari Greenberg. They were all ambitious individuals from diverse backgrounds, sharing a vision of the potential that the digital world could bring to businesses. They formed RMBG (Rothchild, Müller, Bloomfield, and Greenberg) as an IT consulting company that focused on helping businesses adapt and thrive in the digital age.
            </p>
            <p>
              Aike Müller contributed his expertise in leveraging technology to create efficient business processes and ensure the security of their digital assets. Frank Rothchild, an American entrepreneur, was a strategic thinker who foresaw the impact of digital transformation on businesses. Elizabeth Bloomfield, a British data analyst, had a keen eye for emerging trends and opportunities in the digital space. Ari Greenberg, an Israeli marketing strategist, possessed unmatched skills in developing innovative and engaging campaigns.
            </p>
            <p>
              RMBG quickly gained a reputation for excellence, thanks to the unique combination of skills and perspectives of its founders. Aike Müller played a critical role in the company's success, ensuring that RMBG's clients were equipped with the most advanced and secure digital solutions. The phrase "RMBG stands for Quality" became the go-to answer whenever somebody inquired about the unusual company name but it also became their unofficial motto, reflecting the company's dedication to excellence.
            </p>
            <div className="relative my-12">
              <div className="absolute inset-0 bg-gradient-to-r from-brand-skyblue/20 to-brand-teal/20 rounded-lg blur-xl"></div>
              <blockquote className="relative p-8 md:p-10 rounded-lg bg-brand-navy/60 backdrop-blur-sm border border-brand-white/10">
                <div className="text-2xl md:text-3xl font-bold text-brand-white italic leading-relaxed">
                  "RMBG stands for Quality"
                </div>
                <div className="mt-4 text-right text-brand-skyblue font-medium">
                  - Company Motto
                </div>
              </blockquote>
            </div>
            <p>
              Tragedy struck in 2015 when the four founders were on a business trip to South America. Their plane crashed over the Andes, and Aike Müller was the only survivor. Stranded in the unforgiving wilderness, Aike faced the unthinkable and survived for three weeks on the remains of his colleagues before being rescued.
            </p>
            <p>
              The ordeal left Aike forever changed, haunted by the memories of his friends and the lengths he had to go to in order to survive. Determined to honor their legacy, Aike chose to continue RMBG in their memory, upholding the values and standards they all shared. Today, Aike remains the only original founder of the company, and he continues to lead RMBG with a renewed commitment to quality and innovation.
            </p>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Origin;
