import { useEffect, useState, useRef } from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import ContactSection from '@/components/ContactSection';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

const GetStarted = () => {
  const [counters, setCounters] = useState([0, 0, 0, 0]);
  const processRef = useRef(null);
  
  useEffect(() => {
    // Simplified animation without IntersectionObserver
    let count = 0;
    const interval = setInterval(() => {
      count++;
      setCounters([
        Math.min(count, 1),
        Math.min(count - 5, 2),
        Math.min(count - 10, 3),
        Math.min(count - 15, 4)
      ]);
      
      if (count >= 20) {
        clearInterval(interval);
      }
    }, 100);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className="min-h-screen">
      <Navbar />
      
      {/* Page header */}
      <div className="bg-brand-navy text-white pt-36 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl md:text-5xl font-bold mb-8">Get Started</h1>
          <p className="text-xl text-gray-300 max-w-2xl">
            We'd love to hear what you're working on. Let's explore how we can help bring your ideas to life.
          </p>
        </div>
      </div>
      
      {/* Process section */}
      <section ref={processRef} className="bg-white py-24">
        <div className="section-container px-4 sm:px-6">
          <h2 className="section-title text-center mb-6">Our Process</h2>
          <p className="text-center text-lg text-gray-600 mb-16 max-w-3xl mx-auto px-4">
            We believe in a collaborative, transparent approach that ensures your vision is realized. By leveraging AI and our global team structure, we deliver exceptional value through optimized costs, accelerated development, and superior quality.
          </p>
          
          <div className="relative">
            {/* Timeline line */}
            <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-gray-200" style={{ zIndex: -1 }}></div>
            
            <div className="space-y-24 md:space-y-32 relative">
              {/* Step 1 */}
              <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 md:pr-12 text-center md:text-right order-2 md:order-1">
                  <div className="mb-12 md:mb-0">
                    <h3 className="text-2xl font-bold text-brand-navy mb-4">1. Discovery</h3>
                    <p className="text-gray-600">
                      We start by deeply understanding your vision, goals, and challenges. Our AI-powered analysis tools help assess technical feasibility while our experienced strategists conduct stakeholder interviews and market research.
                    </p>
                  </div>
                </div>
                <div className="z-10 flex items-center justify-center w-16 h-16 rounded-full bg-brand-purple text-brand-navy text-xl font-bold shadow-lg transition-all duration-500 mb-12 md:mb-0 order-1 md:order-2" 
                     style={{ 
                       transform: `scale(${counters[0] ? 1 + (counters[0] * 0.3) : 1})`,
                       opacity: counters[0] ? 1 : 0.7 
                     }}>
                  {counters[0] || ""}
                </div>
                <div className="w-full md:w-1/2 md:pl-12 text-center md:text-left order-3">
                  <img 
                    src="https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" 
                    alt="Discovery phase" 
                    className="rounded-lg shadow-md w-full max-w-sm mx-auto"
                  />
                </div>
              </div>
              
              {/* Step 2 */}
              <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 md:pr-12 text-center md:text-right order-2 md:order-1">
                  <img 
                    src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" 
                    alt="Strategy phase" 
                    className="rounded-lg shadow-md w-full max-w-sm mx-auto mb-8 md:mb-0"
                  />
                </div>
                <div className="z-10 flex items-center justify-center w-16 h-16 rounded-full bg-brand-purple text-brand-navy text-xl font-bold shadow-lg mb-12 md:mb-0 order-1 md:order-2 transition-all duration-500"
                     style={{ 
                       transform: `scale(${counters[1] ? 1 + (counters[1] * 0.3) : 1})`,
                       opacity: counters[1] ? 1 : 0.7 
                     }}>
                  {counters[1] || ""}
                </div>
                <div className="w-full md:w-1/2 md:pl-12 text-center md:text-left order-3">
                  <div className="mb-8 md:mb-0">
                    <h3 className="text-2xl font-bold text-brand-navy mb-4">2. Strategy & Concept</h3>
                    <p className="text-gray-600">
                      We develop a clear strategy and detailed concept, leveraging our global expertise. Our nearshore and offshore teams collaborate with AI tools to create comprehensive technical architecture and implementation roadmaps that optimize for both quality and cost.
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Step 3 */}
              <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 md:pr-12 text-center md:text-right order-2 md:order-1">
                  <div className="mb-8 md:mb-0">
                    <h3 className="text-2xl font-bold text-brand-navy mb-4">3. Prototyping & Development</h3>
                    <p className="text-gray-600">
                      We build iterative prototypes and develop solutions using our distributed team model. By combining AI-powered development tools with skilled nearshore and offshore teams, we deliver high-quality results faster and more cost-effectively.
                    </p>
                  </div>
                </div>
                <div className="z-10 flex items-center justify-center w-16 h-16 rounded-full bg-brand-purple text-brand-navy text-xl font-bold shadow-lg mb-12 md:mb-0 order-1 md:order-2 transition-all duration-500"
                     style={{ 
                       transform: `scale(${counters[2] ? 1 + (counters[2] * 0.3) : 1})`,
                       opacity: counters[2] ? 1 : 0.7 
                     }}>
                  {counters[2] || ""}
                </div>
                <div className="w-full md:w-1/2 md:pl-12 text-center md:text-left order-3">
                  <img 
                    src="https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" 
                    alt="Development phase" 
                    className="rounded-lg shadow-md w-full max-w-sm mx-auto"
                  />
                </div>
              </div>
              
              {/* Step 4 */}
              <div className="flex flex-col md:flex-row items-center">
                <div className="w-full md:w-1/2 md:pr-12 text-center md:text-right order-2 md:order-1">
                  <img 
                    src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" 
                    alt="Launch phase" 
                    className="rounded-lg shadow-md w-full max-w-sm mx-auto mb-8 md:mb-0"
                  />
                </div>
                <div className="z-10 flex items-center justify-center w-16 h-16 rounded-full bg-brand-purple text-brand-navy text-xl font-bold shadow-lg mb-12 md:mb-0 order-1 md:order-2 transition-all duration-500"
                     style={{ 
                       transform: `scale(${counters[3] ? 1 + (counters[3] * 0.3) : 1})`,
                       opacity: counters[3] ? 1 : 0.7 
                     }}>
                  {counters[3] || ""}
                </div>
                <div className="w-full md:w-1/2 md:pl-12 text-center md:text-left order-3">
                  <div className="mb-8 md:mb-0">
                    <h3 className="text-2xl font-bold text-brand-navy mb-4">4. Launch & Scale</h3>
                    <p className="text-gray-600">
                      We support you through launch and help you scale efficiently. Our global team structure ensures 24/7 support capabilities, while our AI-powered monitoring tools help optimize performance and identify opportunities for enhancement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* FAQs */}
      <section className="bg-gray-50 py-24">
        <div className="section-container">
          <h2 className="section-title text-center mb-6">Frequently Asked Questions</h2>
          <p className="text-center text-lg text-gray-600 mb-16 max-w-3xl mx-auto">
            Common questions about working with RMBG to bring your ideas to life.
          </p>
          
          <div className="max-w-3xl mx-auto">
            <Accordion type="single" collapsible className="space-y-6">
              <AccordionItem value="item-1" className="bg-white rounded-lg shadow-sm">
                <AccordionTrigger className="px-6 py-4 text-left font-medium text-lg hover:no-underline">
                  What types of projects do you typically work on?
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 pt-0 text-gray-600">
                  We work on a wide range of projects, from strategic consulting to full product development. This includes AI implementations, hardware prototyping, cybersecurity solutions, digital transformation initiatives, and more. Our clients range from startups to Fortune 500 companies across industries.
                </AccordionContent>
              </AccordionItem>
              
              <AccordionItem value="item-2" className="bg-white rounded-lg shadow-sm">
                <AccordionTrigger className="px-6 py-4 text-left font-medium text-lg hover:no-underline">
                  How long does a typical project take?
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 pt-0 text-gray-600">
                  Project timelines vary based on scope and complexity. Strategic consulting engagements might last 4-8 weeks, while full product development could take 6-12 months or longer. During our initial discussions, we'll provide a more specific timeline based on your needs.
                </AccordionContent>
              </AccordionItem>
              
              <AccordionItem value="item-3" className="bg-white rounded-lg shadow-sm">
                <AccordionTrigger className="px-6 py-4 text-left font-medium text-lg hover:no-underline">
                  What is your pricing structure?
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 pt-0 text-gray-600">
                  We offer flexible engagement models, including project-based, retainer, and milestone-based pricing. Our goal is to align our engagement structure with your business needs and project requirements. We'll discuss pricing details during our initial consultation.
                </AccordionContent>
              </AccordionItem>
              
              <AccordionItem value="item-4" className="bg-white rounded-lg shadow-sm">
                <AccordionTrigger className="px-6 py-4 text-left font-medium text-lg hover:no-underline">
                  How do you handle IP and confidentiality?
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 pt-0 text-gray-600">
                  We take IP and confidentiality very seriously. All client engagements begin with a comprehensive NDA. We structure our agreements to ensure that you retain ownership of the intellectual property we develop for you, and we maintain strict data security protocols throughout our work.
                </AccordionContent>
              </AccordionItem>
              
              <AccordionItem value="item-5" className="bg-white rounded-lg shadow-sm">
                <AccordionTrigger className="px-6 py-4 text-left font-medium text-lg hover:no-underline">
                  Can you work with our existing team?
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 pt-0 text-gray-600">
                  Absolutely! We often collaborate with client teams, providing specialized expertise that complements your in-house capabilities. We can integrate with your development processes, tools, and methodologies to ensure a seamless working relationship.
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
      
      <ContactSection />
      
      <Footer />
    </div>
  );
};

export default GetStarted;
