import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';

const Privacy = () => {
  const [isVisible, setIsVisible] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen bg-brand-navy text-brand-white">
      <Navbar />
      <div className="container mx-auto px-6 py-16 md:py-24">
        <div className="max-w-3xl mx-auto">
          {/* Back Button */}
          <Link to="/" className="inline-flex items-center text-brand-slate hover:text-brand-white mb-8 group">
            <ArrowLeft className="mr-2 h-4 w-4 transition-transform group-hover:-translate-x-1" />
            Back to Home
          </Link>

          <h1 className="text-4xl md:text-5xl font-bold mb-6">Privacy Policy</h1>
          
          <p className="text-xl text-brand-slate mb-10">
            Last updated: {currentYear}
          </p>

          {/* Main Content Area */}
          <div className="mt-8 glass-card p-8 md:p-10 rounded-xl border border-brand-white/10 shadow-xl bg-brand-navy/40 backdrop-blur-lg space-y-8 text-lg leading-relaxed text-brand-white/90">
            
            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">1. Introduction</h2>
              <p>
                At RMBG, we respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you visit our website and tell you about your privacy rights and how the law protects you.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">2. Data We Collect</h2>
              <p>
                We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
              </p>
              <ul className="list-disc pl-8 space-y-2 mt-4">
                <li><span className="font-medium">Identity Data</span> includes first name, last name, username or similar identifier.</li>
                <li><span className="font-medium">Contact Data</span> includes email address and telephone numbers.</li>
                <li><span className="font-medium">Technical Data</span> includes internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.</li>
                <li><span className="font-medium">Usage Data</span> includes information about how you use our website, products and services.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">3. How We Use Your Data</h2>
              <p>
                We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
              </p>
              <ul className="list-disc pl-8 space-y-2 mt-4">
                <li>To provide you with information, products or services that you request from us.</li>
                <li>To improve our website, products or services.</li>
                <li>For administrative and business purposes.</li>
                <li>To send you communications about our products and services that may be of interest to you.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">4. Legal Basis for Processing</h2>
              <p>
                Under GDPR, we must have a legal basis for processing your personal data. We rely on the following legal bases:
              </p>
              <ul className="list-disc pl-8 space-y-2 mt-4">
                <li><span className="font-medium">Consent</span>: Where you have given us explicit consent to process your data.</li>
                <li><span className="font-medium">Contract</span>: Where processing is necessary for the performance of a contract with you.</li>
                <li><span className="font-medium">Legitimate interests</span>: Where processing is necessary for our legitimate interests, provided those interests don't override your fundamental rights and freedoms.</li>
                <li><span className="font-medium">Legal obligation</span>: Where we need to process your data to comply with a legal obligation.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">5. Cookies</h2>
              <p>
                Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. We use cookies to improve your experience on our website.
              </p>
              <p className="mt-2">
                You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">6. Third-Party Services</h2>
              <p>
                Our website may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.
              </p>
              <p className="mt-2">
                We may use third-party service providers to help us operate our business and the website or administer activities on our behalf. These may include:
              </p>
              <ul className="list-disc pl-8 space-y-2 mt-4">
                <li>Analytics providers (such as Google Analytics)</li>
                <li>Email service providers</li>
                <li>Customer relationship management systems</li>
                <li>Hosting providers</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">7. International Transfers</h2>
              <p>
                We may transfer your personal data to countries outside the European Economic Area (EEA). Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it by implementing at least one of the following safeguards:
              </p>
              <ul className="list-disc pl-8 space-y-2 mt-4">
                <li>Transfer to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission.</li>
                <li>Use of specific contracts approved by the European Commission which give personal data the same protection it has in Europe.</li>
                <li>Transfer to providers based in the US that are part of Privacy Shield or similar certification schemes.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">8. Data Retention</h2>
              <p>
                We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">9. Data Security</h2>
              <p>
                We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know.
              </p>
              <p className="mt-2">
                We have procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">10. Your Rights</h2>
              <p>
                Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:
              </p>
              <ul className="list-disc pl-8 space-y-2 mt-4">
                <li>Request access to your personal data</li>
                <li>Request correction of your personal data</li>
                <li>Request erasure of your personal data</li>
                <li>Object to processing of your personal data</li>
                <li>Request restriction of processing your personal data</li>
                <li>Request transfer of your personal data</li>
                <li>Right to withdraw consent</li>
              </ul>
              <p className="mt-4">
                You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">11. Changes to This Privacy Policy</h2>
              <p>
                We may update this privacy policy from time to time. The updated version will be indicated by an updated "Last updated" date and the updated version will be effective as soon as it is accessible. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-4 text-brand-mint">12. Contact Us</h2>
              <p>
                If you have any questions about this privacy policy or our privacy practices, please contact us at:
              </p>
              <div className="mt-4 pl-4 border-l-4 border-brand-skyblue py-2">
                <p>RMBG BV</p>
                <p>Valschermkade 16</p>
                <p>1059CD Amsterdam</p>
                <p>The Netherlands</p>
                <p className="mt-2">Email: <a href="mailto:info@rmbg.nl" className="text-brand-mint hover:underline">info@rmbg.nl</a></p>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy; 