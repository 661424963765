import { Button } from "@/components/ui/button";
import { ArrowRight, Mail } from "lucide-react";

const ContactSection = () => {
  return (
    <section id="contact" className="bg-white py-20">
      <div className="section-container">
        <h2 className="section-title text-center">Get Started</h2>
        <p className="text-center text-lg text-gray-600 mb-12 max-w-2xl mx-auto">
          We'd love to hear what you're working on. Let's explore how we can help bring your ideas to life.
        </p>
        
        <div className="max-w-3xl mx-auto text-center">
          <p className="text-lg mb-8">
            Ready to start a conversation? Reach out to us directly via email.
          </p>
          
          <a 
            href="mailto:info@rmbg.nl"
            className="inline-flex items-center justify-center px-6 py-4 rounded-lg bg-gradient-to-r from-brand-teal to-[#9AE6B4] text-white hover:opacity-90 transition-all duration-300 group shadow-lg hover:shadow-xl transform hover:-translate-y-1"
          >
            <Mail className="mr-2 h-5 w-5" />
            Contact Us
            <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
          </a>
          
          <p className="mt-8 text-gray-500">
            We'll get back to you as soon as possible.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
