import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import AboutSection from '@/components/AboutSection';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { Linkedin, Twitter, User, CheckCircle, Lightbulb, ShieldCheck } from 'lucide-react';

const About = () => {
  return (
    <>
      <Navbar />
      <main>
        {/* Hero Section - Use brand-navy bg, brand-white primary text, brand-slate secondary */}
        <section className="bg-brand-navy text-brand-white pt-32 pb-16">
          <div className="container mx-auto px-6 text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Mission</h1>
            <p className="text-xl text-brand-slate max-w-2xl mx-auto">
              Empowering innovators with transformative technology solutions.
            </p>
          </div>
        </section>

        {/* Team Section - Use brand-silver bg, brand-navy for titles, brand-skyblue for role, brand-slate for text/icons */}
        <section className="bg-brand-silver py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center text-brand-navy mb-6">Meet the Founder</h2> 
            <p className="text-center text-lg text-brand-slate mb-12 max-w-3xl mx-auto">
              RMBG is led by Aike Müller, a seasoned strategist and technologist passionate about turning ambitious ideas into reality.
            </p>
            <div className="flex justify-center">
              <div className="bg-brand-white rounded-lg shadow-md overflow-hidden text-center max-w-sm">
                <img 
                  src="/lovable-uploads/c69b84ec-87bf-4710-b3c6-64688acd5f4d.png" 
                  alt="Aike Müller" 
                  className="w-full h-64 object-cover"
                />
                <div className="p-6">
                  <h3 className="font-bold text-xl mb-2 text-brand-navy">Aike Müller</h3>
                  <p className="text-brand-skyblue mb-4">Founder & Chief Strategist</p>
                  <p className="text-brand-slate mb-4">
                    Visionary leader driving innovation through strategic insight.
                  </p>
                  <div className="flex justify-center space-x-4">
                    {/* Use slate for icons, skyblue for hover */} 
                    <a href="https://www.linkedin.com/in/aikemueller/" className="text-brand-slate hover:text-brand-skyblue">
                      <Linkedin size={20} />
                    </a>
                    <a href="https://x.com/themuli" className="text-brand-slate hover:text-brand-skyblue">
                      <Twitter size={20} />
                    </a>
                  </div>
                  {/* Link uses brand-teal (primary) and brand-skyblue (secondary accent) for hover */}
                  <Button asChild variant="link" className="mt-4 text-brand-teal hover:text-brand-skyblue px-0">
                    <Link to="/founder-bio">View Full Bio</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Values Section - Use brand-white bg, brand-navy section bg, brand-mint for icons, brand-slate for text */}
        <section className="py-20 bg-brand-white">
          <div className="container mx-auto px-6">
            <div className="bg-brand-navy text-brand-white rounded-xl px-6 py-12 md:p-12 relative overflow-hidden">
               {/* Decorative elements - use teal and skyblue */}
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-brand-skyblue/20 rounded-full opacity-50 blur-2xl"></div>
              <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-brand-teal/20 rounded-full opacity-50 blur-2xl"></div>
              
              <div className="relative z-10">
                <h2 className="text-3xl font-bold text-center mb-6">Our Core Values</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
                  <div>
                    <CheckCircle size={40} className="mx-auto mb-4 text-brand-mint" />
                    <h3 className="font-semibold text-xl mb-2">Collaboration</h3>
                    <p className="text-brand-slate">We build success together with our clients.</p>
                  </div>
                  <div>
                    <Lightbulb size={40} className="mx-auto mb-4 text-brand-mint" />
                    <h3 className="font-semibold text-xl mb-2">Innovation</h3>
                    <p className="text-brand-slate">Pushing boundaries to create impactful solutions.</p>
                  </div>
                  <div>
                    <ShieldCheck size={40} className="mx-auto mb-4 text-brand-mint" />
                    <h3 className="font-semibold text-xl mb-2">Integrity</h3>
                    <p className="text-brand-slate">Operating with transparency and ethical standards.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default About;
