import { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ChevronRight, Cpu, ShieldCheck, BrainCircuit, Code, Lightbulb, Briefcase, Check } from "lucide-react";
import { Target } from "lucide-react";

const ExpertiseSection = () => {
  const [activeTab, setActiveTab] = useState("strategy");
  
  return (
    <section id="expertise" className="bg-brand-silver py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center text-brand-navy mb-4">Core Expertise</h2>
        <p className="text-center text-lg text-brand-slate mb-12 max-w-3xl mx-auto">
          We combine strategic insight with deep technical capabilities across AI, hardware, software, and security.
        </p>

        <Tabs defaultValue="strategy" className="w-full max-w-4xl mx-auto">
          <TabsList className="grid w-full grid-cols-3 mb-8 bg-brand-navy/5 p-2 rounded-lg">
            <TabsTrigger value="strategy" className="data-[state=active]:bg-brand-white data-[state=active]:text-brand-teal data-[state=active]:shadow-md text-brand-slate">Strategy</TabsTrigger>
            <TabsTrigger value="innovation" className="data-[state=active]:bg-brand-white data-[state=active]:text-brand-teal data-[state=active]:shadow-md text-brand-slate">Innovation</TabsTrigger>
            <TabsTrigger value="cybersecurity" className="data-[state=active]:bg-brand-white data-[state=active]:text-brand-teal data-[state=active]:shadow-md text-brand-slate">Cybersecurity</TabsTrigger>
          </TabsList>
          
          {/* Strategy Content */}
          <TabsContent value="strategy" className="mt-8 p-6 bg-brand-white rounded-lg shadow-sm">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="flex-shrink-0">
                <Target size={48} className="text-brand-skyblue" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-brand-navy mb-2">Strategic Foresight</h3>
                <p className="text-brand-slate mb-4">
                  We help you navigate the technological landscape, identify opportunities, and build robust digital strategies aligned with your business goals.
                </p>
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Digital transformation roadmaps</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Technology opportunity scans</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Product portfolio optimization</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Business model innovation</li>
                </ul>
              </div>
            </div>
          </TabsContent>

          {/* Innovation Content */}
          <TabsContent value="innovation" className="mt-8 p-6 bg-brand-white rounded-lg shadow-sm">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="flex-shrink-0">
                <Lightbulb size={48} className="text-brand-skyblue" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-brand-navy mb-2">Technology Execution</h3>
                <p className="text-brand-slate mb-4">
                  From concept to production, we build cutting-edge solutions, integrating AI, hardware, and software seamlessly and securely.
                </p>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-sm">
                  <div>
                    <h4 className="font-medium text-brand-navy">AI done right</h4>
                    <p className="text-brand-slate text-xs">Trustworthy, explainable, and human-centered AI applications</p>
                  </div>
                  <div>
                    <h4 className="font-medium text-brand-navy">Hardware development</h4>
                    <p className="text-brand-slate text-xs">From IoT and smart devices to industrial prototypes</p>
                  </div>
                  <div>
                    <h4 className="font-medium text-brand-navy">Connected products</h4>
                    <p className="text-brand-slate text-xs">Integrating software, sensors, and secure connectivity</p>
                  </div>
                </div>
              </div>
            </div>
          </TabsContent>

          {/* Cybersecurity Content */}
          <TabsContent value="cybersecurity" className="mt-8 p-6 bg-brand-white rounded-lg shadow-sm">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="flex-shrink-0">
                <ShieldCheck size={48} className="text-brand-skyblue" />
              </div>
              <div>
                <h3 className="text-xl font-semibold text-brand-navy mb-2">Secure by Design</h3>
                <p className="text-brand-slate mb-4">
                  Embedding security and privacy into every layer of your technology stack, from hardware to cloud.
                </p>
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Secure by design development</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Privacy-preserving AI implementation</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Embedded security for hardware</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Compliance and certification support</li>
                </ul>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </section>
  );
};

export default ExpertiseSection;
