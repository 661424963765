import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Menu, X } from "lucide-react";
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navLinks = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/about' },
    { name: 'Expertise', href: '/expertise' },
    { name: 'Get Started', href: '/get-started' },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <nav 
      className={cn(
        "fixed w-full z-50 transition-all duration-300",
        isScrolled ? "bg-white/90 shadow-md backdrop-blur-sm py-3" : "bg-transparent py-5"
      )}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <img 
                src="/lovable-uploads/e1472ca2-4818-44a5-8f6c-8a184ff535b0.png" 
                alt="RMBG Logo" 
                className={cn(
                  "h-10 w-auto transition-all duration-300",
                  isScrolled ? "" : "brightness-0 invert"
                )}
              />
            </Link>
          </div>
          
          {/* Desktop menu */}
          <div className="hidden md:flex items-center space-x-10">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.href}
                className={cn(
                  "font-medium transition-all duration-200 text-base relative py-2",
                  isScrolled 
                    ? isActive(link.href)
                      ? "text-brand-purple" 
                      : "text-brand-indigo hover:text-brand-blue" 
                    : isActive(link.href)
                      ? "text-brand-mint" 
                      : "text-brand-white hover:text-brand-mint",
                  isActive(link.href) && "after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-current"
                )}
              >
                {link.name}
              </Link>
            ))}
            <Button 
              className="px-4 py-2 rounded text-gray-700"
              style={{ backgroundColor: "#9AE6B4" }}
            >
              Contact Us
            </Button>
          </div>
          
          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className={cn(
                "inline-flex items-center justify-center p-2 rounded-md focus:outline-none",
                isScrolled ? "text-brand-indigo hover:text-brand-blue" : "text-brand-white hover:text-brand-mint"
              )}
            >
              {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden glass-card mt-2 mx-4 rounded-xl overflow-hidden animate-fade-in">
          <div className="px-3 py-2 space-y-1">
            {navLinks.map((link) => (
              <Link
                key={link.name}
                to={link.href}
                className={cn(
                  "block px-4 py-3 rounded-md text-base font-medium transition-colors",
                  isActive(link.href)
                    ? "text-brand-purple bg-brand-purple/10"
                    : "text-brand-indigo hover:text-brand-blue hover:bg-brand-mist"
                )}
                onClick={() => setIsMenuOpen(false)}
              >
                {link.name}
              </Link>
            ))}
            <div className="pt-2 pb-1">
              <Button 
                className="px-4 py-2 rounded text-gray-700 w-full" 
                style={{ backgroundColor: "#9AE6B4" }}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
