import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger animation on mount
    setIsVisible(true); 
  }, []);

  return (
    <div className="relative min-h-screen w-full overflow-hidden flex items-center justify-center text-center">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center -z-20"
        style={{ backgroundImage: `url('/lovable-uploads/hero.png')` }}
        aria-hidden="true" 
      />

      {/* Dark Overlay */}
      <div 
        className="absolute inset-0 bg-gradient-to-br from-brand-navy/80 via-brand-navy/70 to-brand-navy/80 -z-10" 
        aria-hidden="true" 
      />

      {/* Content Container */}
      <div className="relative container mx-auto px-6 z-10">
        <div 
          className={`max-w-3xl mx-auto transition-all duration-1000 ${
            isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        >
          {/* Text Content */}
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-brand-white mb-6 leading-tight">
            From <span className="text-brand-mint">Vision</span> to <span className="text-brand-mint">Global Impact</span>
          </h1>
            
          <p className="text-lg md:text-xl text-brand-white mb-8 leading-relaxed">
            Curious what disruption looks like—done right?
            Let's build something extraordinary together.
          </p>

          {/* Buttons */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button 
              asChild
              size="lg"
              className="bg-gradient-to-r from-brand-teal to-[#9AE6B4] text-white hover:opacity-90 transition-all duration-300 group shadow-lg hover:shadow-xl transform hover:-translate-y-1"
            >
              <Link to="/get-started" className="flex items-center justify-center">
                Learn More
                <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
              </Link>
            </Button>

            <Button 
              asChild
              size="lg"
              variant="outline" 
              className="border-2 border-brand-mint/70 text-gray-700 hover:bg-brand-mint/10 hover:text-brand-white hover:border-brand-mint transition-all duration-300 shadow-lg"
            >
              <Link to="/expertise" className="flex items-center justify-center">
                Our Expertise
              </Link>
            </Button>
          </div>
        </div>
      </div>

      {/* Scroll Indicator */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-10">
        <div className="w-5 h-9 border-2 border-brand-white/50 rounded-full flex justify-center">
          <div className="w-1 h-2 bg-brand-mint rounded-full mt-2 animate-bounce" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
