import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import ExpertiseSection from '@/components/ExpertiseSection';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { ChevronRight, Cpu, ShieldCheck, BrainCircuit, Code, BarChart3, Check, Wifi, Target, ArrowRight } from 'lucide-react';

const Expertise = () => {
  return (
    <>
      <Navbar />
      <main>
        {/* Hero Section - Use brand-navy bg, brand-white text, brand-slate secondary */}
        <section className="bg-brand-navy text-brand-white pt-32 pb-16">
          <div className="container mx-auto px-6 text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Expertise</h1>
            <p className="text-xl text-brand-slate max-w-2xl mx-auto">
              Bridging strategy, technology, and security for impactful innovation.
            </p>
          </div>
        </section>

        {/* Core Areas Section - Use brand-white bg, brand-navy titles, brand-slate text, brand-silver card bg, brand-skyblue icons */}
        <section className="bg-brand-white py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center text-brand-navy mb-6">Core Service Areas</h2>
            <p className="text-center text-lg text-brand-slate mb-12 max-w-3xl mx-auto">
              We provide end-to-end support, from initial concept validation to full-scale development and deployment, always with security integrated from the start.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {/* Card 1: AI & ML */}
              <div className="bg-brand-silver rounded-lg p-8">
                <BrainCircuit size={40} className="text-brand-skyblue mb-4" />
                <h3 className="text-xl font-semibold text-brand-navy mb-3">AI & Machine Learning</h3>
                <p className="text-brand-slate mb-6">
                  Developing trustworthy, explainable AI solutions tailored to your business needs.
                </p>
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Machine learning model development</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Explainable AI implementation</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Ethical AI governance frameworks</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> AI integration with existing systems</li>
                </ul>
              </div>
              {/* Card 2: Hardware & IoT */}
              <div className="bg-brand-silver rounded-lg p-8">
                <Cpu size={40} className="text-brand-skyblue mb-4" />
                <h3 className="text-xl font-semibold text-brand-navy mb-3">Hardware & IoT Development</h3>
                <p className="text-brand-slate mb-6">
                  Designing and building secure, connected hardware from prototype to production.
                </p>
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> IoT device prototyping</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Embedded systems design</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> PCB design and manufacturing</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Production-ready hardware development</li>
                </ul>
              </div>
              {/* Card 3: Connected Products */}
              <div className="bg-brand-silver rounded-lg p-8">
                <Wifi size={40} className="text-brand-skyblue mb-4" />
                <h3 className="text-xl font-semibold text-brand-navy mb-3">Connected Products & Systems</h3>
                <p className="text-brand-slate mb-6">
                  Integrating software, hardware, and connectivity for seamless user experiences.
                </p>
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Cloud-connected device development</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Mobile app integration</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Sensor fusion and data processing</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> User experience design for connected products</li>
                </ul>
              </div>
              {/* Card 4: Strategy & Transformation */}
              <div className="bg-brand-silver rounded-lg p-8">
                <Target size={40} className="text-brand-skyblue mb-4" />
                <h3 className="text-xl font-semibold text-brand-navy mb-3">Strategy & Digital Transformation</h3>
                <p className="text-brand-slate mb-6">
                  Guiding your organization through complex technological shifts and opportunities.
                </p>
                <ul className="space-y-2 text-sm">
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Digital maturity assessment</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Technology roadmap development</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Legacy system modernization</li>
                  <li className="flex items-center text-brand-slate"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Digital-first business model innovation</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section - Use brand-silver bg, brand-navy/skyblue gradient, white text, navy/teal button */}
        <section className="py-20 bg-brand-silver">
          <div className="container mx-auto px-6">
            <div className="bg-gradient-to-r from-brand-navy to-brand-skyblue text-brand-white rounded-xl px-6 py-12 md:p-12 text-center md:text-left">
              <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                <div>
                  <h2 className="text-3xl font-bold mb-4">Ready to Build the Future?</h2>
                  {/* Use brand-white/80 for subdued text on gradient */} 
                  <p className="text-lg md:text-xl text-brand-white/80 mb-8 max-w-3xl">
                    Let's discuss how our expertise can accelerate your innovation journey. Schedule a consultation today.
                  </p>
                </div>
                {/* Button: Use navy-to-teal gradient */} 
                <Button 
                  asChild 
                  size="lg" 
                  className="bg-gradient-to-r from-brand-navy to-brand-teal text-brand-white hover:opacity-90 transition duration-300 flex-shrink-0"
                >
                  <Link to="/get-started">
                    Get Started <ArrowRight className="ml-2 h-5 w-5" />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Expertise;
