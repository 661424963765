import { Link } from "react-router-dom";
import { ArrowUpRight, Linkedin, Twitter, Instagram, Github, Mail } from "lucide-react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-brand-navy text-brand-slate py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          {/* Logo & About */}
          <div>
            <Link to="/" className="flex items-center gap-2 mb-4">
              <img 
                src="/lovable-uploads/e1472ca2-4818-44a5-8f6c-8a184ff535b0.png" 
                alt="RMBG Logo" 
                className="h-8 w-auto brightness-0 invert" 
              />
             
            </Link>
            <h4 className="font-semibold text-brand-white mb-2">About Us</h4>
            <p className="text-sm">RMBG BV is registered as #76905284 at Chamber of Commerce in Amsterdam, The Netherlands</p>
          </div>

          {/* Navigation */}
          <div>
            <h4 className="font-semibold text-brand-white mb-4">Navigation</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-brand-white transition-colors">About Us</Link></li>
              <li><Link to="/expertise" className="hover:text-brand-white transition-colors">Expertise</Link></li>
              <li><Link to="/get-started" className="hover:text-brand-white transition-colors">Get Started</Link></li>
            </ul>
          </div>

          {/* Where to find us */}
          <div>
            <h4 className="font-semibold text-brand-white mb-4">Where to find us</h4>
            <p className="text-sm">Valschermkade 16</p>
            <p className="text-sm">1059CD Amsterdam</p>
          </div>
          
          {/* Social Links */}
          <div>
            <h4 className="font-semibold text-brand-white mb-4">Connect</h4>
            <div className="mt-4">
              <a 
                href="mailto:info@rmbg.nl" 
                className="inline-flex items-center gap-2 text-brand-mint hover:text-brand-white transition-colors"
              >
                <Mail size={16} />
                info@rmbg.nl
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-brand-white/10 pt-8 text-center text-sm">
          © {currentYear} RMBG. All rights reserved.
          <span className="mx-2">|</span>
          <Link to="/privacy" className="hover:text-brand-white">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
