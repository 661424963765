import { Card, CardContent } from "@/components/ui/card";
import { Briefcase, Shield, Lightbulb } from "lucide-react";

const AboutSection = () => {
  return (
    <section id="about" className="bg-brand-mist py-20">
      <div className="section-container">
        <h2 className="section-title text-center">About Us</h2>
        
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <div className="space-y-6">
            <p className="text-lg text-brand-navy">
              <span className="font-bold">RMBG</span> is a boutique advisory and development firm based in Amsterdam, The Netherlands. For over a decade, we've been helping companies turn ideas into scalable, secure, and future-proof solutions.
            </p>
            <p className="text-lg text-brand-navy">
              We specialize in identifying disruption potential—whether through new technologies, business models, or user experiences—and rapidly translating it into prototypes, hardware, AI models, and commercial products.
            </p>
            <p className="text-lg text-brand-navy font-medium">
              We don't just advise. We build.
            </p>
            <p className="text-lg text-brand-navy">
              Our clients range from global corporations and mission-driven NGOs to ambitious startups. Our work spans industries such as consumer electronics, industrial hardware, AI, cybersecurity, and sustainability.
            </p>
          </div>
          
          <div className="relative">
            <div className="absolute -top-6 -left-6 w-24 h-24 bg-brand-purple/10 rounded-full z-0"></div>
            <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-brand-blue/10 rounded-full z-0"></div>
            <div className="relative z-10 bg-brand-white p-8 rounded-xl shadow-xl">
              <div className="aspect-w-4 aspect-h-3 mb-6">
                <img 
                  src="/lovable-uploads/workshop.jpeg" 
                  alt="Technology workspace" 
                  className="rounded-lg object-cover w-full h-full"
                />
              </div>
              <div className="border-l-4 border-brand-purple pl-4">
                <p className="text-brand-gray italic">
                  "From early-stage R&D to full product development and go-to-market execution, we partner closely with your team to make innovation real."
                </p>
                <div className="mt-4">
                  <p className="font-bold text-brand-indigo">Aike Müller</p>
                  <p className="text-sm text-brand-gray">Founder and CEO</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="mt-16 grid md:grid-cols-3 gap-6">
          <Card className="border border-brand-gray/20 bg-brand-white hover:shadow-md transition-shadow">
            <CardContent className="pt-6">
              <div className="mb-4 w-12 h-12 rounded-full bg-brand-blue/10 flex items-center justify-center">
                <Briefcase className="h-6 w-6 text-brand-blue" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-brand-indigo">Strategy</h3>
              <p className="text-brand-gray">
                We help you define what's next. Our strategic services blend technology insight with business thinking.
              </p>
            </CardContent>
          </Card>
          
          <Card className="border border-brand-gray/20 bg-brand-white hover:shadow-md transition-shadow">
            <CardContent className="pt-6">
              <div className="mb-4 w-12 h-12 rounded-full bg-brand-blue/10 flex items-center justify-center">
                <Lightbulb className="h-6 w-6 text-brand-blue" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-brand-indigo">Innovation</h3>
              <p className="text-brand-gray">
                Innovation is more than brainstorming—it's building. We specialize in AI, hardware development, and connected products.
              </p>
            </CardContent>
          </Card>
          
          <Card className="border border-brand-gray/20 bg-brand-white hover:shadow-md transition-shadow">
            <CardContent className="pt-6">
              <div className="mb-4 w-12 h-12 rounded-full bg-brand-blue/10 flex items-center justify-center">
                <Shield className="h-6 w-6 text-brand-bue" />
              </div>
              <h3 className="text-xl font-semibold mb-2 text-brand-indigo">Cybersecurity</h3>
              <p className="text-brand-gray">
                Cybersecurity is baked into everything we do. We protect your ideas, your data, and your users—by design.
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
