import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { ArrowLeft, Linkedin, Twitter, Instagram, Medal, BookOpen, ArrowUpRight, Zap, Check, Award, MessageCircle } from 'lucide-react';
import { useEffect, useState } from 'react';

const FounderBio = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen bg-brand-silver">
      <Navbar />
      
      {/* Header section */}
      <section className="bg-brand-navy text-brand-white pt-32 pb-16 relative overflow-hidden">
        {/* Decorative elements - Use teal and skyblue */} 
        <div className="absolute inset-0 opacity-10 pointer-events-none">
          <div className="absolute top-0 left-0 w-1/2 h-full bg-gradient-to-br from-brand-teal/30 to-transparent blur-3xl"></div>
          <div className="absolute bottom-0 right-0 w-1/2 h-full bg-gradient-to-tl from-brand-skyblue/30 to-transparent blur-3xl"></div>
        </div>
        
        <div className="container mx-auto px-6 relative z-10">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            <div className="lg:w-1/3">
              <img 
                src="/lovable-uploads/c69b84ec-87bf-4710-b3c6-64688acd5f4d.png" 
                alt="Aike Müller" 
                className="rounded-full w-48 h-48 md:w-64 md:h-64 object-cover mx-auto lg:mx-0 border-4 border-brand-skyblue shadow-lg"
              />
            </div>
            <div className="lg:w-2/3 text-center lg:text-left">
              <h1 className="text-4xl md:text-5xl font-bold mb-3">Aike Müller</h1>
              <p className="text-2xl text-brand-mint mb-6">Founder & Chief Strategist</p>
              <p className="text-lg text-brand-slate mb-8">
                A seasoned entrepreneur and cybersecurity expert with proven success in hardware product development, firmware engineering, and scaling global tech teams. Specializes in bringing innovative products from concept to mass production.
              </p>
              <div className="flex justify-center lg:justify-start space-x-4">
                <a href="https://www.linkedin.com/in/aikemueller/" className="text-brand-slate hover:text-brand-white transition-colors">
                  <Linkedin size={24} />
                </a>
                <a href="https://x.com/themuli" className="text-brand-slate hover:text-brand-white transition-colors">
                  <Twitter size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Bio content */}
      <section className="py-16 md:py-24">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
            {/* Left Column: Detailed Bio - Use brand-white bg, slate/navy/teal for text */}
            <div className="lg:col-span-2 bg-brand-white p-8 rounded-lg shadow-md">
              <Button asChild variant="ghost" className="mb-6 -ml-4 text-brand-slate hover:text-brand-teal hover:bg-brand-silver group">
                <Link to="/about" className="inline-flex items-center">
                  <ArrowLeft className="mr-2 h-4 w-4 transition-transform group-hover:-translate-x-1" />
                  Back to About
                </Link>
              </Button>

              <h2 className="text-2xl font-semibold text-brand-navy mb-6">Journey & Vision</h2>
              <div className="prose prose-lg max-w-none text-brand-slate prose-headings:text-brand-navy prose-a:text-brand-teal hover:prose-a:text-brand-skyblue prose-strong:text-brand-navy">
                <h3 className="text-xl font-semibold text-brand-navy mb-4">Early Career & Foundation</h3>
                <p>
                  Aike's journey began in M&A at PwC, where he quickly transitioned into consulting to pursue his passion for innovation. In this role, he led transformative projects across diverse sectors including airfreight, government, sustainable supply chains, and retail, developing a comprehensive understanding of how technology can drive business value.
                </p>

                <h3 className="text-xl font-semibold text-brand-navy mb-4 mt-8">Entrepreneurial Success</h3>
                <p>
                  As the founder of Keezel, Aike demonstrated his ability to execute both technical vision and market strategy. He successfully led the development of an award-winning IoT cybersecurity device from initial concept through to mass production and global distribution. This journey encompassed every aspect of product development—from hardware design and firmware development to manufacturing coordination in Asia and worldwide logistics.
                </p>

                <div className="my-8 p-6 bg-brand-navy/5 rounded-lg border-l-4 border-brand-teal">
                  <blockquote className="text-brand-navy italic">
                    "Success in hardware comes from mastering both the technical details and the global ecosystem. It's about building bridges between innovation and real-world implementation."
                  </blockquote>
                </div>

                <h3 className="text-xl font-semibold text-brand-navy mb-4">Strategic Leadership</h3>
                <p>
                  Beyond Keezel, Aike has guided multiple startups and scale-ups through complete hardware product lifecycles. His hands-on approach to interim technical leadership has helped companies navigate the complex journey from prototype to production, with expertise in sourcing, Design for Manufacturing (DFM), certification, testing, and post-launch support.
                </p>

                <h3 className="text-xl font-semibold text-brand-navy mb-4 mt-8">Vision for Scale</h3>
                <p>
                  Throughout his career, Aike has demonstrated exceptional skill in building and coordinating international teams of remote engineers and suppliers. His experience in raising capital through various channels—including Indiegogo, StartEngine, and venture capital—showcases his ability to align technical innovation with business growth.
                </p>
                <p>
                  Today, Aike continues to advise and build scalable hardware and software platforms worldwide, focusing on speed, cost-efficiency, and global reach. His approach combines deep technical expertise with strategic business acumen, helping companies navigate the complexities of modern product development and market expansion.
                </p>
              </div>
            </div>

            {/* Right Column: Quick Facts / Highlights */}
            <div className="space-y-8">
              <div className="bg-brand-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-brand-navy mb-4 flex items-center">
                  <Zap size={20} className="mr-2 text-brand-skyblue" /> Core Expertise
                </h3>
                <ul className="space-y-2 text-brand-slate">
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Hardware Product Development</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Cybersecurity Architecture</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Global Team Management</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Manufacturing & DFM</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Product Strategy & Innovation</li>
                </ul>
              </div>

              <div className="bg-brand-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-brand-navy mb-4 flex items-center">
                  <Award size={20} className="mr-2 text-brand-skyblue" /> Key Achievements
                </h3>
                <ul className="space-y-3 text-brand-slate">
                  <li className="flex items-start">
                    <Check className="mr-2 h-4 w-4 text-brand-mint flex-shrink-0 mt-1" />
                    Founded and scaled Keezel, bringing an award-winning IoT cybersecurity device from concept to global distribution
                  </li>
                  <li className="flex items-start">
                    <Check className="mr-2 h-4 w-4 text-brand-mint flex-shrink-0 mt-1" />
                    Successfully raised capital through multiple channels including Indiegogo, StartEngine, and venture capital
                  </li>
                  <li className="flex items-start">
                    <Check className="mr-2 h-4 w-4 text-brand-mint flex-shrink-0 mt-1" />
                    Led innovation projects across airfreight, government, sustainable supply chains, and retail at PwC
                  </li>
                  <li className="flex items-start">
                    <Check className="mr-2 h-4 w-4 text-brand-mint flex-shrink-0 mt-1" />
                    Guided multiple hardware startups from prototype to production as interim technical leader
                  </li>
                </ul>
              </div>

              <div className="bg-brand-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-brand-navy mb-4 flex items-center">
                  <BookOpen size={20} className="mr-2 text-brand-skyblue" /> Areas of Focus
                </h3>
                <ul className="space-y-2 text-brand-slate">
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Full Product Lifecycle Management</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> International Supply Chain Optimization</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Remote Team Building & Coordination</li>
                  <li className="flex items-center"><Check className="mr-2 h-4 w-4 text-brand-mint" /> Strategic Technical Advisory</li>
                </ul>
              </div>
              
              <div className="bg-gradient-to-br from-brand-teal to-brand-skyblue p-6 rounded-lg shadow-md text-brand-white">
                <h3 className="text-xl font-semibold mb-4 flex items-center">
                  <MessageCircle size={20} className="mr-2" /> Let's Connect
                </h3>
                <p className="mb-4 text-brand-white/90">Ready to bring your hardware or software vision to life? Let's discuss how we can help.</p>
                <Button asChild className="w-full bg-brand-white text-brand-teal hover:bg-brand-silver transition-colors">
                  <Link to="/get-started">
                    Contact Aike
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default FounderBio;
